import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import "tachyons/css/tachyons.min.css";
import "../css/common.css";
import "../css/pages/special-freight.css";

import Header from "../components/Header";
import TitlePage from "../components/ui/TitlePage";
import SectionTextPage from "../components/ui/SectionTextPage";
import Footer from "../components/Footer";
import ListText from "../components/ui/ListText";
import Seo from "../components/SeoComponent";
import Contact from "../components/Contact";

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <>
      <Seo title={t("about-us.metadata.name")} description={t("about-us.metadata.description")} />
      <Header />
      <SectionTextPage>
        <TitlePage title={t("about-us.title")} style={{ paddingLeft: 0 }} />
        <p>{t("about-us.p1")}</p>
        <p>{t("about-us.p2")}</p>
        <p>{t("about-us.p3")}</p>
        <p>{t("about-us.p4")}</p>
        <p>{t("about-us.p5")}</p>
      </SectionTextPage>

      <SectionTextPage mode="dark">
        <p className="section-subtitle">{t("about-us.s2.1")}</p>
        <ListText text={t("about-us.s2.l1")} />
        <ListText text={t("about-us.s2.l2")} />
        <ListText text={t("about-us.s2.l3")} />
      </SectionTextPage>

      <SectionTextPage>
        <Contact />
      </SectionTextPage>

      <Footer />
    </>
  );
};

export default AboutUs;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
